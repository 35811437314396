// extracted by mini-css-extract-plugin
export var pdpService = "style-mod--pdp-service--cBNkN";
export var serviceContent = "style-mod--service-content--5B_fL";
export var scImg = "style-mod--sc-img--2bHoO";
export var sTitle = "style-mod--s-title--1-Pqg";
export var yStars = "style-mod--y-stars--3sXVD";
export var ratingText = "style-mod--rating-text--2cxXZ";
export var clipStar = "style-mod--clip-star--3Qncg";
export var sPrice = "style-mod--s-price--2AnOU";
export var sDescription = "style-mod--s-description--qWEyj";
export var accordionCard = "style-mod--accordion-card--37h4c";
export var cardHeader = "style-mod--card-header--3Foh6";
export var accordionIcon = "style-mod--accordion-icon--2mshg";
export var accordionIconOpen = "style-mod--accordion-icon-open--GCsJk";
export var accordionTitle = "style-mod--accordion-title--qFJcR";
export var accordionSubtitle = "style-mod--accordion-subtitle--z7qp0";
export var accordionBody = "style-mod--accordion-body--nLujc";
export var sBtn = "style-mod--s-btn--_yvRb";
export var serviceHighlight = "style-mod--service-highlight--2Mm0v";
export var shTitle = "style-mod--sh-title--BG-_H";
export var highlight = "style-mod--highlight--21JzR";
export var highlightContent = "style-mod--highlight-content--3Z_03";
export var hImg = "style-mod--h-img--79pIx";
export var hTitle = "style-mod--h-title--ji7hD";
export var hSubtitle = "style-mod--h-subtitle--owuQ2";
export var serviceTwoColumn = "style-mod--service-two-column--2UP0c";
export var textContent = "style-mod--text-content--2EY2z";
export var tcTitle = "style-mod--tc-title--2SSY1";
export var tcSubtitle = "style-mod--tc-subtitle--3OvWC";
export var tcBtn = "style-mod--tc-btn--3QiA2";
export var imgContent = "style-mod--img-content--2_smw";
export var tcImg = "style-mod--tc-img--1vGi_";