import React, { useState, useEffect } from "react";
import BaseLayout from "layouts/base-layout";
import "scss/main.scss";
import "scss/vendor/carousels.scss";
import renderModules from "helpers/render-modules";
import PdpService from "components/pdp-service";
import ProductReviews from "components/product-reviews";
import ProductStructuredData from "components/seo/product";
import { getReviewSummary } from "data/yotpo-reviews";

export default function Product(props) {
  const { product, settings, meta, structured_data } = props.pageContext;
  const [summary, setSummary] = useState({});

  useEffect(async () => {
    const thisSummary = await getReviewSummary(product.shopifyId);
    setSummary(thisSummary);
  }, []);

  return (
    <BaseLayout
      data={settings}
      meta={meta || product?.meta}
    >
      <ProductStructuredData meta={structured_data || {}}/>
      <PdpService
        product={product}
        reviewSummary={summary}
      />

      { product?.modules && renderModules(product?.modules) }
      <ProductReviews shopifyId={product?.shopifyId} />
    </BaseLayout>
  );
}
