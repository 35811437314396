import React, { useState, useContext } from "react";
import { Accordion, Container, Card } from "react-bootstrap";
import {
  pdpService, serviceContent, yStars, clipStar, ratingText,
  scImg, sTitle,sPrice, sDescription, accordionCard,cardHeader,
  accordionIcon, accordionBody, accordionTitle, accordionSubtitle, sBtn, accordionIconOpen
} from "./style.mod.scss";

import RichText from "components/rich-text";
import Money from "components/money";
import GlobalContext from "services/global-context";
import {  StarFill, ChevronDown } from "react-bootstrap-icons";
import getImagePath from "helpers/get-image-path";

const PdpService = ({ product, reviewSummary }) => {
  const { addToCart } = useContext(GlobalContext);
  const variant = product?.variants[0];
  if (!variant) return <></>;

  const {
    productInfo: {
      miniDescription,
      productTabs,
    },
    sourceData: { priceV2, compareAtPriceV2 }
  } = variant;

  const [open, setOpen] = useState({});

  const round = (avg) => avg && Math.round(avg);
  const prec = (avg) => avg && Number.parseFloat(avg).toPrecision(2);
  const scrollToReviews = () => document.getElementById("y-product-reviews")?.scrollIntoView({
    behavior: "smooth", block: "start"
  });

  const SummaryReviews = () => {
    if (reviewSummary?.total_review === 0) return <></>;

    return (
      <a className={yStars} onClick={scrollToReviews}>
        {
          reviewSummary?.average_score &&
            Array(round(reviewSummary.average_score)).fill().map(() => (
              <div key={Math.random()} className={clipStar}><StarFill /></div>
            ))
        }
        {
          reviewSummary?.average_score &&
            <span className={ratingText}>{prec(reviewSummary.average_score)} ({reviewSummary.total_review} Bewertungen)</span>
        }
      </a>
    );
  };

  return (
    <div className={pdpService}>
      <Container>
        <div className={serviceContent} >
          <img className={scImg} src={getImagePath(variant.variantImages && variant.variantImages[0].asset._ref)} alt="[Missing Image]"></img>
          <div>
            <p className={sTitle}>{product.title}</p>
            <SummaryReviews />

            <p className={sPrice}>
              <Money value={priceV2.amount} currency={priceV2.currencyCode} />
              {compareAtPriceV2.currencyCode !== "NONE" && <span><Money value={compareAtPriceV2.amount} currency={compareAtPriceV2.currencyCode} /></span>}
            </p>
            <div className={sDescription}>
              <RichText
                blocks={miniDescription}
              />
            </div>
            <Accordion className="pointer">
              {
                productTabs.map((_tab, index)=>(
                  <Card key={index} className={accordionCard}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={_tab._key}
                      className={cardHeader}
                      onClick={() => setOpen(open === _tab._key ? null : _tab._key)}
                    >
                      <p className={accordionTitle}>{_tab.title}</p>
                      <p className={accordionSubtitle}>{_tab.subtitle}</p>
                      <span className={`${accordionIcon} ${_tab._key !== open ? "" : accordionIconOpen}`}>
                        <ChevronDown/>
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={_tab._key}>
                      <Card.Body className={accordionBody}>
                        <RichText blocks={_tab.content} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))
              }
            </Accordion>
            <button className={sBtn} onClick={() => variant?.id && addToCart(variant)}>IN DEN WARKOBEN</button>
          </div>

        </div>
      </Container>
    </div>
  );
};

export default PdpService;
